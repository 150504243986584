import {SidebarMenu} from "../../core/interfaces/sidebar-menu";

export const MENU: SidebarMenu[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.ANALYTICS.TEXT',
    icon: 'pie-chart',
    link: '/analytics'
  },
  {
    id: 3,
    label: 'MENUITEMS.BOOKINGS.TEXT',
    icon: 'monitor',
    link: '/bookings',
  },
  {
    id: 4,
    label: 'MENUITEMS.MANAGE_HOTEL.TEXT',
    isTitle: true
  },
  {
    id: 5,
    label: 'MENUITEMS.INFO.TEXT',
    icon: 'info',
    link: '/hotel-information',
  },
  {
    id: 6,
    label: 'MENUITEMS.CONTACT.TEXT',
    icon: 'phone',
    link: '/contact-information',
  },
  {
    id: 7,
    label: 'MENUITEMS.FEATURES.TEXT',
    icon: 'star',
    subItems: [
      {
        id: 8,
        label: 'MENUITEMS.FEATURES.LIST.SERVICES.TEXT',
        link: '/features/services',
        parentId: 7
      },
      {
        id: 9,
        label: 'MENUITEMS.FEATURES.LIST.AMENITIES.TEXT',
        link: '#',
        parentId: 7
      },
      {
        id: 10,
        label: 'MENUITEMS.FEATURES.LIST.THEMES.TEXT',
        link: '#',
        parentId: 7
      },
      {
        id: 11,
        label: 'MENUITEMS.FEATURES.LIST.ACTIVITIES.TEXT',
        link: '#',
        parentId: 7
      }
    ]
  },
  {
    id: 12,
    label: 'MENUITEMS.MESSAGES.TEXT',
    icon: 'mail',
    link: '#',
  },
  {
    id: 13,
    label: 'MENUITEMS.IMAGES.TEXT',
    icon: 'image',
    link: '/hotel-gallery',
  },
  {
    id: 14,
    label: 'MENUITEMS.LOCATION.TEXT',
    icon: 'map',
    link: '#',
  },
  {
    id: 15,
    label: 'MENUITEMS.META.TEXT',
    icon: 'check-circle',
    link: '#',
  },
  {
    id: 16,
    label: 'MENUITEMS.ADMIN.TEXT',
    isTitle: true,
    admin: true
  },
  {
    id: 17,
    label: 'MENUITEMS.CONFIG.TEXT',
    icon: 'settings',
    link: '#',
    admin: true
  },
  {
    id: 18,
    label: 'MENUITEMS.CHANNEL_MANAGER.TEXT',
    icon: 'hexagon',
    link: '#',
    admin: true
  },
  {
    id: 19,
    label: 'MENUITEMS.LABELS.TEXT',
    icon: 'tag',
    link: '#',
    admin: true
  },
  {
    id: 20,
    label: 'MENUITEMS.USPS.TEXT',
    icon: 'bookmark',
    link: '#',
    admin: true
  },
  {
    id: 21,
    label: 'MENUITEMS.MANAGERS.TEXT',
    icon: 'users',
    link: '#',
    admin: true
  },
];

