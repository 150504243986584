import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../core/services/language.service';
import { UserService } from '../../core/services/user.service';
import { EventService } from '../../core/services/event.service';
import { HotelService } from '../../core/services/hotel.service';
import { DialogService } from '../../core/services/dialog.service';
import { User } from '../../core/interfaces/user';
import { Profile } from '../../core/interfaces/profile';
import { Hotel } from '../../core/interfaces/hotel';
import {Roles} from "../../core/enums/roles";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  flagvalue: any;
  cookieValue: any;
  valueset: any;
  user: User = new User();
  profile: Profile = new Profile();
  protected readonly _roles = Roles;
  public managedHotel: Hotel | null = null;

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  constructor(
    private router: Router,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private userService: UserService,
    private eventService: EventService,
    private hotelService: HotelService,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.profile = this.user.profile;
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/united-kingdom.svg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.loadSelectedHotel();
    this.eventService.managedHotelChanged$.subscribe((hotel: Hotel | null) => {
      this.managedHotel = hotel;
    });
  }

  loadSelectedHotel(): void {
    const selectedHotelId = this.hotelService.getSelectedHotel();
    if (selectedHotelId) {
      this.hotelService.getHotels([]).subscribe((response) => {
        const hotels = response.data;
        const selectedHotel = hotels.find((hotel: Hotel) => hotel.id.toString() === selectedHotelId);
        this.managedHotel = selectedHotel ? selectedHotel : null;
      });
    }
  }

  openHotelsDialog() {
    const dialogRef = this.dialogService.openHotelSelectionDialog();
    dialogRef.afterClosed().subscribe((hotelId: string | null) => {
      if (hotelId) {
        this.hotelService.setSelectedHotel(hotelId);
        this.loadSelectedHotel();
      }
    });
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  toggleMobileMenu(event: any) {
    var currentSIdebarSize = document.body.getAttribute('data-sidebar-size');
        window.onload = function () {
          document.body.setAttribute('data-sidebar-size', 'sm');
            if (window.innerWidth >= 1024 && window.innerWidth <= 1366) {
                document.body.setAttribute('data-sidebar-size', 'sm');
                // updateRadio('sidebar-size-small')
            }
        }
    document.body.classList.toggle('sidebar-enable');
    if (window.innerWidth >= 992) {
      if (currentSIdebarSize == null) {
          (document.body.getAttribute('data-sidebar-size') == null || document.body.getAttribute('data-sidebar-size') == "lg") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'lg')
      } else if (currentSIdebarSize == "md") {
          (document.body.getAttribute('data-sidebar-size') == "md") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'md')
      } else {
          (document.body.getAttribute('data-sidebar-size') == "sm") ? document.body.setAttribute('data-sidebar-size', 'lg') : document.body.setAttribute('data-sidebar-size', 'sm')
      }
    }
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/account/login']);
  }

  listLang = [
    { translationKey: 'LANGUAGES.ENGLISH', flag: 'assets/images/flags/united-kingdom.svg', lang: 'en' },
    { translationKey: 'LANGUAGES.GERMAN', flag: 'assets/images/flags/germany.svg', lang: 'de' },
    { translationKey: 'LANGUAGES.FRENCH', flag: 'assets/images/flags/france.svg', lang: 'fr' },
    { translationKey: 'LANGUAGES.ITALIAN', flag: 'assets/images/flags/italy.svg', lang: 'it' },
  ];
}
