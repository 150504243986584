import { Component, OnInit } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { ContactInformationService } from '../../core/services/contact-information.service';
import { CountryService } from '../../core/services/country.service';
import { Country } from '../../core/interfaces/country';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.scss'
})
export class ContactInformationComponent implements OnInit {
// bread crumb items
breadCrumbItems!: Array<{}>;
public hotel: any = {};
public hotel_contact: any = {};
radioSelection: string = '';
radioOptions: any = {};
showLightCard: boolean = false;
countries: any;
languages: { id: number, name: string }[] = [
  { id: 215, name: 'German' },
  { id: 83, name: 'German' },
  { id: 110, name: 'Italian' },
  { id: 15, name: 'Austrian' },
  { id: 75, name: 'French' }
];

constructor(private hotelService: HotelService,private contactInformationService: ContactInformationService,private countryService: CountryService)
{
  this.radioOptions = [
    { id: 1, gender: 'male', label: 'Male' },
    { id: 2, gender: 'female', label: 'Female' },
    // Add more options as needed
];
}
ngOnInit(): void {
  this.subscribeToSelectedHotel();
  this.fetchByCountries();
  this.contactInformationService.getShowLightCard().subscribe(show => {
    this.showLightCard = show;
  });
  this.initiliazeBreadCrumbs();
  }
  initiliazeBreadCrumbs(){
   /**
   * BreadCrumb
   */
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Contact information', active: true }
    ];
  }
  fetchByCountries(){
    this.countryService.getAllCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;
        this.fetchRegionsByHotelCountry();
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }
  fetchRegionsByHotelCountry(): void {
    if (this.hotel.country_id) {
      const countryIndex = this.countries.findIndex((country:any) => country.id === this.hotel.country_id);
    }
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        console.log(this.hotel);
        this.hotel_contact = this.hotel.hotel_contact;
        this.fetchRegionsByHotelCountry();
      }
    });
  }
}
