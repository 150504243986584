import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AnalyticsComponent} from "./analytics/analytics.component";
import {BookingsComponent} from "./bookings/bookings.component";
import {HotelInformationComponent} from "./hotel-information/hotel-information.component";
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { HotelGalleryComponent } from './hotel-gallery/hotel-gallery.component';
import { ServicesComponent } from './features/services/services.component';

const routes: Routes = [
  {
    path: 'analytics',
    component: AnalyticsComponent
  },
  {
    path: 'bookings',
    component: BookingsComponent
  },
  {
    path: 'hotel-information',
    component: HotelInformationComponent
  },
  {
    path: 'contact-information',
    component: ContactInformationComponent
  },
  {
    path: 'hotel-gallery',
    component: HotelGalleryComponent
  },
  {
    path: 'features/services',
    component: ServicesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
