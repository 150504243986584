import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelService } from '../../../core/services/hotel.service';
import { Hotel } from '../../../core/interfaces/hotel';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent {
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  // Array to hold the cards
  cards: Array<any> = [];
  public hotel: any = {};
  private cardIndexToDelete: number | null = null;
  priceOptions = [
    { name: 'per guest_night' },
    { name: 'per guest_stay' },
    { name: 'per night' },
    { name: 'per stay' }
  ];
  daysOfWeek = [
    { id: 'mon', key: 'monday', label: 'Monday' },
    { id: 'tue', key: 'tuesday', label: 'Tuesday' },
    { id: 'wed', key: 'wednesday', label: 'Wednesday' },
    { id: 'thu', key: 'thursday', label: 'Thursday' },
    { id: 'fri', key: 'friday', label: 'Friday' },
    { id: 'sat', key: 'saturday', label: 'Saturday' },
    { id: 'sun', key: 'sunday', label: 'Sunday' }
  ];
  languages = [
    { key: 'de', label: 'Deutsch' },
    { key: 'en', label: 'English' },
    { key: 'fr', label: 'Français' },
    { key: 'it', label: 'Italiano' }
  ];
    // Array of button configurations
    roomCategories = [
      { id: 1, label: 'Double room comfort', doubleRoomComfort: true },
      { id: 2, label: 'Doubleroom Junior Suite', doubleRoomComfort: true }
    ];
  // Property to track the checkbox state
  isServiceApplicableToAllRooms: boolean = false;

  constructor(private hotelService: HotelService, private modalService: NgbModal) {}
  ngOnInit():void{
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      this.hotel = hotel;
      console.log(this.hotel);
    });
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Features' },
      { label: 'Services',active: true }
    ];
        // Load cards from localStorage on component initialization
        this.loadCards();
        // Ensure at least one card is present
        if (this.cards.length === 0) {
          this.addCard(); // Add a default card if none exist
        }
  }

  addCard() {
    this.cards.push({
      descriptions: this.languages.reduce((acc, lang) => ({ ...acc, [lang.key]: '' }), {}),
      dateRange: '',
      isPermanentService: false,
      dealDays: this.daysOfWeek.reduce((acc, day) => ({ ...acc, [day.key]: false }), {}),
      price: '0.00',
      priceOption: this.priceOptions[0].name,
      isMandatory: false,
      allowAllRooms: false
    });
    this.saveCards();
  }

    // Method to load cards from localStorage
    private loadCards(): void {
      const cards = localStorage.getItem('cards');
      if (cards) {
        this.cards = JSON.parse(cards);
      }
    }
  
    // Method to save cards to localStorage
    private saveCards(): void {
      localStorage.setItem('cards', JSON.stringify(this.cards));
    }

  // Method to open the delete confirmation modal
  openDeleteModal(staticDataModal:any): void {
    this.cardIndexToDelete = staticDataModal;
    this.modalService.open(staticDataModal, { backdrop: 'static', keyboard: false, centered: true });
  }

  // Method to confirm the deletion of a card
  confirmDelete(modal: any): void {
    if (this.cardIndexToDelete !== null) {
      this.cards.splice(this.cardIndexToDelete, 1);
      this.saveCards();
    }
    this.cardIndexToDelete = null;
    modal.close();
  }

  // DragDrop Functionality
  onDrop(event: CdkDragDrop<any[]>) {
    const previousIndex = this.cards.findIndex(card => card === event.item.data);
    const currentIndex = event.currentIndex;
    const movedCard = this.cards[previousIndex];

    // Reorder the list
    this.cards.splice(previousIndex, 1);
    this.cards.splice(currentIndex, 0, movedCard);
  }

    // Toggle method for checkbox
    toggleApplicableToAllRooms(event: any) {
      this.isServiceApplicableToAllRooms = event.target.checked;
      this.roomCategories.forEach(roomCategory => roomCategory.doubleRoomComfort = this.isServiceApplicableToAllRooms);
    }
}
