<!-- start page title -->
<app-pagetitle title="Contact Information" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<div class="row mt-3">
    <div class="col-lg-4">
        <div class="card position-absolute z-1 fw-normal ms-3 contact-card-bg" *ngIf="showLightCard">
            <h6 class="card-header bg-light">Data Usage</h6>
            <div class="card-body">
                <p class="card-text">The contact details will be used by HotelCard only internally and are not visible on the website.</p>
            </div>
        </div>
    </div>
</div>
<!-- end page title -->
<div class="row mt-3">
    <div class="col-xl-12">
        <div class="card card-h-100">
            <div class="card-body">
                <div class="">
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3">
                                <div class="form-check form-check-inline" *ngFor="let option of radioOptions">
                                    <input class="form-check-input form-input-radio form-input-bg" type="radio" name="formRadios" [id]="'formRadios-' + option.id"
                                           [(ngModel)]="hotel_contact.gender" [value]="option.gender" [checked]="hotel_contact.gender === option.gender">
                                    <label class="form-check-label" [for]="'formRadios-' + option.id">
                                        {{ option.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="firstname">First Name</label>
                              <input type="text" class="form-control form-input-bg" id="firstname" [value]="hotel_contact.first_name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label" for="lastname">Last Name</label>
                            <input type="text" class="form-control form-input-bg" id="lastname" [value]="hotel_contact.last_name">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                              <div class="mb-3">
                                  <label class="form-label" for="role">Role</label>
                                  <input type="text" class="form-control form-input-bg" id="role" [value]="hotel_contact.role">
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="mb-3">
                                  <label class="form-label" for="email">E-mail address</label>
                                  <input type="text" class="form-control form-input-bg" id="email" [value]="hotel_contact.email">
                              </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="booking_email">Hotel Booking email</label>
                                <input type="text" class="form-control form-input-bg" id="booking_email" [value]="hotel.booking_email">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="general_questions">Email for general questions</label>
                                <input type="text" class="form-control form-input-bg" id="general_questions" [value]="hotel.general_email">
                            </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="phone_hotel">Phone Hotel(incl.international area code)</label>
                                <input type="text" class="form-control form-input-bg" id="phone_hotel" [value]="hotel_contact.phone_number">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="phone_contact">Phone Contact(incl.international area code)</label>
                                <input type="text" class="form-control form-input-bg" id="phone_contact" [value]="hotel_contact.phone_number">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                              <label class="form-label" for="booking_request">Language for booking request</label>
                              <select id="booking_request" class="form-control form-input-bg">
                                <option *ngFor="let language of languages" [value]="language.name" [selected]="hotel.country_id === language.id">{{ language.name }}</option>
                              </select>
                            </div>
                          </div>
                      </div>
                      <div class="mt-4">
                          <button type="submit" class="btn btn-primary submit_btn w-md">Submit</button>
                      </div>
                    </form>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->
