<!-- start page title -->
<app-pagetitle title="Hotel Information" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="row mt-3">
    <div class="col-xl-12">
        <div class="card card-h-100">
            <div class="card-body">
                <div class="">
                    <form>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label" for="hotelname">Hotel Name</label>
                              <input type="text" class="form-control form-input-bg" id="hotelname" [value]="hotel.name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label" for="starrating">Star Rating</label>
                            <select class="form-select form-input-bg" id="rating" name="rating" [(ngModel)]="hotel.rating">
                              <option *ngFor="let rating of rating" [value]="rating.value" [selected]="hotel.rating == rating.value">{{rating.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                              <div class="mb-3">
                                  <label class="form-label" for="country">Country</label>
                                <select class="form-select form-input-bg" id="country" name="country">
                                    <option *ngFor="let country of countries" [value]="country.name" [selected]="hotel.country_id == country.id">
                                        {{ country.name }}
                                    </option>
                                </select>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="mb-3">
                                  <label class="form-label" for="currency">Currency</label>
                                  <input type="text" class="form-control form-input-bg" id="currency" value="{{hotel.currency}}">
                              </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="region">Region</label>
                                <select class="form-select form-input-bg" id="region" name="region">
                                    <option value="">Select</option>
                                    <option *ngFor="let region of regions" [value]="region.name" [selected]="hotel.region_id == region.id">{{ region.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="town">Town</label>
                                <input type="text" class="form-control form-input-bg" id="town" [value]="hotel.city">
                            </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label" for="street">Street</label>
                                <input type="text" class="form-control form-input-bg" id="street" [value]="hotel.street">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label" for="housenumber">House number</label>
                                <input type="text" class="form-control form-input-bg" id="housenumber" [value]="hotel.number">
                            </div>
                        </div>
                        <div class="col-md-4">
                          <div class="mb-3">
                              <label class="form-label" for="postcode">Postcode</label>
                              <input type="text" class="form-control form-input-bg" id="postcode" [value]="hotel.postal_code">
                          </div>
                      </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="website">Website</label>
                                <input type="text" class="form-control form-input-bg" id="website" [value]="hotel.website">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="hoteltype">Hotel Type</label>
                                <!-- <input type="text" class="form-control form-input-bg" id="hoteltype" [value]="hotel.hotel_type"> -->
                                <select class="form-select form-input-bg" id="hoteltype" name="hoteltype" [(ngModel)]="hotel.hotel_type">
                                  <option *ngFor="let hotelType of hotelType" [value]="hotel.hotel_type" [selected]="hotel.hotel_type == hotelType.value">{{hotelType.value}}</option>
                                </select>
                            </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label class="form-label" for="checkinfrom">Check-in from</label>

                                <input type="time" class="form-control form-input-bg" id="checkinfrom" [value]="hotel.checkin_from">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label class="form-label" for="checkintill">Check-in untill</label>
                                <input type="time" class="form-control form-input-bg" id="checkintill" [value]="hotel.checkin_till">
                            </div>
                        </div>
                        <div class="col-md-1">
                          <div class="mb-3">
                          <br/>
                            <label> OR </label>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <br/>
                          <input class="form-check-input" type="checkbox" id="checkin_full_time" name="checkin_full_time" [(ngModel)]="hotel.checkin_full_time"
                          [checked]="hotel.checkin_full_time == 1" >
                          <label class="form-check-label" for="opentilllate">
                             &nbsp; Open till late
                          </label>
                        </div>
                        <div class="col-md-2">
                          <div class="mb-3">
                              <label class="form-label" for="checkout">Check-out untill</label>
                              <input type="time" class="form-control form-input-bg" id="checkout-input" [value]="hotel.checkout_till">
                          </div>
                      </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <br/>
                            <input class="form-check-input mr-8 form-input-radio form-input-bg" type="checkbox" id="nophone" name="phone_requests" [(ngModel)]="hotel.phone_requests"
                            [checked]="hotel.phone_requests == 1">
                            <label class="form-check-label">&nbsp; no phone requests</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="availability">Close availability at this time on the day of arrival</label>
                                <input  type="time" class="form-control form-input-bg" id="availability-input" [(ngModel)]="hotel.availability_close_time">
                            </div>
                        </div>
                      </div>
                      <div class="mt-4">
                          <button type="submit" class="btn btn-primary submit_btn w-md">Submit</button>
                      </div>
                    </form>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">Hotel description</h4>
    </div><!-- end card header -->
    <div class="card-body">
        <!-- Nav tabs -->
        <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs nav-justified">
            <li [ngbNavItem]="1">
                <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                  <span class="d-none d-sm-block">Deutsch</span>
                </a>
                <ng-template ngbNavContent>
                  <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                  style="height: 200px"> {{hotel.description['de']}}</textarea>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                    <span class="d-none d-sm-block">English</span>
                </a>
                <ng-template ngbNavContent>
                  <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                  style="height: 200px"> {{hotel.description['en']}}</textarea>
                </ng-template>
            </li>
            <li [ngbNavItem]="3">
                <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                    <span class="d-none d-sm-block">Français</span>
                </a>
                <ng-template ngbNavContent>
                  <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2"
                  style="height: 200px"> {{hotel.description['fr']}}</textarea>
                </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                  <span class="d-none d-sm-block">Italiano</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.description['it']}}</textarea>
              </ng-template>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content p-3 text-muted">
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div> <!-- end card body -->
</div>

<div class="card">
  <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">Hotel secondary description</h4>
  </div><!-- end card header -->
  <div class="card-body">
      <!-- Nav tabs -->
      <ul ngbNav #justifyNav="ngbNav" [activeId]="1" class="nav nav-tabs nav-justified">
          <li [ngbNavItem]="1">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                  <span class="d-none d-sm-block">Deutsch</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['de']}}</textarea>
              </ng-template>
          </li>
          <li [ngbNavItem]="2">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                  <span class="d-none d-sm-block">English</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['en']}}</textarea>
              </ng-template>
          </li>
          <li [ngbNavItem]="3">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                  <span class="d-none d-sm-block">Français</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['fr']}}</textarea>
              </ng-template>
          </li>
          <li [ngbNavItem]="4">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                  <span class="d-none d-sm-block">Italiano</span>
              </a>
              <ng-template ngbNavContent>
                <textarea class="form-control form-input-bg" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 200px"> {{hotel.secondary_description['it']}}</textarea>
              </ng-template>
          </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content p-3 text-muted">
          <div [ngbNavOutlet]="justifyNav"></div>
      </div><!-- end tab content -->
  </div><!-- end crad body -->
</div>
