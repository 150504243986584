import { NgModule } from '@angular/core';
import { NgbModule , NgbNavModule, NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import {AnalyticsComponent} from "./analytics/analytics.component";
import {PagesRoutingModule} from "./pages-routing.module";
import { BookingsComponent } from './bookings/bookings.component';
import { HotelInformationComponent } from './hotel-information/hotel-information.component';
import {ComponentsModule} from "../components/components.module";
import {FormsModule} from "@angular/forms";
import { BookingListSortableDirective } from '../core/directives/booking-sorting.directive';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { MatIconModule } from '@angular/material/icon';
import { HotelGalleryComponent } from './hotel-gallery/hotel-gallery.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { provideNgxMask } from 'ngx-mask';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DragDropModule } from '@angular/cdk/drag-drop';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};
import { ServicesComponent } from './features/services/services.component';

@NgModule({
  declarations: [
    AnalyticsComponent,
    BookingsComponent,
    HotelInformationComponent,
    BookingListSortableDirective,
    ContactInformationComponent,
    HotelGalleryComponent,
    ServicesComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgbPaginationModule,
    ComponentsModule,
    NgbDropdownModule,
    FormsModule,
    NgbModule,
    NgApexchartsModule,
    MatIconModule,
    NgbNavModule,
    DropzoneModule,
    DragDropModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    provideNgxMask(),
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ]
})
export class PagesModule { }
