import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactInformationService {
  private showLightCardSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor() { }
  
  setShowLightCard(show: boolean): void {
    this.showLightCardSubject.next(show);
  }
  
  getShowLightCard(): Observable<boolean> {
    return this.showLightCardSubject.asObservable();
  }
}
