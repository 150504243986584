// booking-stats.service.ts

import { Injectable } from '@angular/core';
import { HotelService } from './hotel.service';
import { BookingResponse } from '../interfaces/booking-response';
import { Booking } from '../interfaces/booking';
import { Hotel } from '../interfaces/hotel';
import { Observable, Subscription } from 'rxjs';
import { HttpService } from './http.service';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingStatsService {
  bookings: Booking[] = [];
  selectedHotel: Hotel | null = null;
  totalRecords: number = 0;
  private subscriptions: Subscription = new Subscription();
  private apiUrl = 'bookings/';

  constructor(private hotelService: HotelService, private http: HttpService) {
    this.subscriptions.add(
      this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
        this.selectedHotel = hotel;
        if (hotel) {
          this.getBookingStats();
        } else {
          this.bookings = [];
        }
      })
    );
  }

  getBookingStats(): void {
    if (this.selectedHotel) {
      const params = {
        locale: 'en'
      };

      this.hotelService?.getSelectedHotelBookings(params).subscribe((response: BookingResponse | null) => {
        if (response) {
          this.bookings = response.data.map(booking => {
            const checkinDate = new Date(booking.checkin_date);
            const checkoutDate = new Date(booking.checkout_date);
            const timeDiff = Math.abs(checkoutDate.getTime() - checkinDate.getTime());
            const nrOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
            const nrOfGuests = booking.adults + booking.children + booking.infants;

            return {
              ...booking,
              dateOfArrival: booking.checkin_date,
              nrOfNights,
              nrOfGuests
            };
          });
          this.totalRecords = response.total;
        }
      });
    }
  }
  getHotelAll(hotelId: string | number, params: any): Observable<BookingResponse> {
    return this.http.get(`bookings/all/${hotelId}`, { params });
  }
  confirmBooking(bookingData: { hotel_id: number, booking_id: number, key: string }): Observable<any> {
    return this.http.post(`${this.apiUrl}confirm`, bookingData);
  }
}
